import React, { useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import bemCn from 'bem-cn';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './index.scss';

const b = bemCn('modal-mobile');

const ModalMobile = ({ isOpen = false, toggle, title = '', children, className = '', resetFilter, onReset = () => {} }) => {
    const timeout = { timeout: 100 };

    useEffect(() => {
        if (!isOpen) {
            const bodyEle = document.querySelector('body');
            setTimeout(() => {
                bodyEle.style.overflow = '';
            }, 120);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={Boolean(isOpen)}
            toggle={toggle}
            className={`${b()} ${className}`}
            backdropTransition={timeout}
            modalTransition={timeout}
            modalClassName="modal-mobile-wrapper"
        >
            <ModalBody>
                {title && (
                    <div className={b('title')}>
                        <p className="snif-s1 snif-regular mb-0">
                            <span className="text">{title}</span>
                            <SvgCross2 className={b('close-button')} onClick={toggle} />
                            <span className={b('rectangle-mobile')} />
                            {resetFilter && (
                                <span className="snif-s2 snif-medium reset-all" onClick={onReset}>
                                    Reset all
                                </span>
                            )}
                        </p>
                    </div>
                )}
                <div className={b('content')}>{children}</div>
            </ModalBody>
        </Modal>
    );
};

export default ModalMobile;
